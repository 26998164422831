.join{
    margin-top: 5rem;
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}
.left-j{
    color: white;
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
}
.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid #f48916;
    border-radius: 20%;
    margin-top: -10px;
}
.left-j>div{
    display: flex;
    gap: 1rem;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: lightgray;
}
::placeholder{
    color: lightgray;
}
.btn-j{
    background-color: #f48916;
    color: white;
}
@media screen and (max-width: 768px) {
    .join{
        flex-direction: column;
        gap: 1rem;
    }
    .left-j{
        font-size: x-large;
        flex-direction: column;
    }
    .right-j{
        padding: 2rem;
    }
}