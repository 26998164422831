.plans-container{
    margin-top: 3rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.plans> :nth-child(2){
    background-color: #fb683f;
    width: 16rem;
    gap: 3rem;
}
.plans> :nth-child(2)>button{
    color: #fb683f;
}
.plan{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 2rem;
    background-color: #656565;
    padding: 1.5rem;
    width: 15rem;
}
.plan> :nth-child(1){
    /* transform: scale(1.1); */
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 3rem;
}
.plan> :nth-child(2){
    font-weight: bold;
    font-size: 3rem;
}
.plan> :nth-child(4){
    font-weight: bold;
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans-blur-1{
    width: 20rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}
.plans-blur-2{
    width: 20rem;
    height: 23rem;
    top: 10rem;
    right: 0;
}
@media screen and (max-width: 768px) {
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }

    .plans-blur-2{
        width: 10rem;
        display: none;
    }
}