.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.header-manu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
}
.header-manu>li{
    cursor: pointer;
    text-decoration: none;
}
.header-manu>li>a{
    cursor: pointer;
    text-decoration: none;
    color: white;
}
.header-manu>li>a:hover{
    cursor: pointer;
    color: orange;
}
.list-man{
    background-color: #484a4e;
    padding: 0.5rem;
    border-radius: 5px;
    /* right: 20px; */
}
.list-man>img{
    width: 1.5rem;
    height: 1.5rem;
   
}
@media screen and (max-width: 768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-manu{
        flex-direction: column;
        padding: 2rem;
        background-color: #484a4e;
        top: 10px;
    }
}