*{
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
body{
  background-color: #484a4e;
}

.stroke-text{
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.btn{
  background-color: white;
  font-weight: bold;
  padding: 0.5rem;
  border: 4px solid transparent;
  transition: 300ms all;
  align-items: center;
}
.blur{
  position: absolute;
  background-color: rgb(253, 120, 43, 0.69);
  border-radius: 50%;
  filter: blur(150px);
  z-index: -1;
}